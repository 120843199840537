import '../../vendor/mmenu-js';
import 'mhead-js';

import initMobileSearch from './search';

import './styles.scss';

const initNavigation = function () {
  initMobileSearch();

  const navMenu = new Mmenu('#mobile-navigation', {
    'extensions': [
      'theme-white',
      'border-full',
      'position-front',
      'position-left',
      'pagedim-black',
      'fx-panels-slide-0'
    ],
    navbar: {
      title: 'Каталог'
    },
    classNames: {
      selected: 'selected',
    },
    navbars: [
      {
        'position': 'bottom',
        'content': [
          '<a class=\'phone\' href=\'tel:89035556661\'>+7 (903) 555-666-1</a>',
          '<div class=\'mm-navbar--row\'> \
           <a href=\'/contacts\'> Контакты </a> \
           <a href=\'/delivery\'> Доставка </a> </div>'
        ]
      }
    ],
  },
  {
    'language': 'ru'
  });

  new Mhead('.header-mobile', { scroll: { hide: 300 } });

  const closeBtnEl = document.createElement('a');
  closeBtnEl.classList.add('mm-menu__close');
  const closeBtnIconEl = document.createElement('i');
  closeBtnIconEl.classList.add('dl-icon-close');
  closeBtnEl.appendChild(closeBtnIconEl);

  document.querySelector('.mm-menu').appendChild(closeBtnEl);

  document.querySelector('.mm-menu__close').addEventListener('click', (e) => {
    e.preventDefault();
    navMenu.close();
  });
};

export default initNavigation;
